import React, { useState } from 'react';
import dynamic from "next/dynamic"
import Aux from '../../atom/children';
import SectionLoader from '../../atom/loader/sectionLoader';

//import Highchart from '../../atom/chart/pmschart';
const Highchart = dynamic(
    () => import("../../atom/chart/pmschart"),
    { ssr: false }
);
const PerFo = (props) => {
    const [activeGraphTab, setActiveGraphTab] = useState(1);

    // uncomment below state once the data is available in BOP and IOPSV2
    // const [graphTab, setGraphTab] = useState([
    //      {
    //          "title": "Business Opportunity\n Portfolio",
    //          "cagr": '2' in props.apiresp.ChartCAGR ? props.apiresp.ChartCAGR[2] : 0,
    //          "tabNo": 2
    //      },
    //      {
    //          "title": "Next Trillion\n Dollar Opportunity",
    //          "cagr": '3' in props.apiresp.ChartCAGR ? props.apiresp.ChartCAGR[3] : 0,
    //          "tabNo": 3
    //      },
    //      {
    //          "title": "Value\n Strategy",
    //          "cagr": '1' in props.apiresp.ChartCAGR ? props.apiresp.ChartCAGR[1] : 0,
    //          "tabNo": 1
    //      },
    //      {
    //          "title": "India Opportunity\n Portfolio Strategy",
    //          "cagr": '4' in props.apiresp.ChartCAGR ? props.apiresp.ChartCAGR[4] : 0,
    //          "tabNo": 4
    //      },
    //      {
    //          "title": "India Opportunity\n Portfolio Strategy V2",
    //          "cagr": '5' in props.apiresp.ChartCAGR ? props.apiresp.ChartCAGR[5] : 0,
    //          "tabNo": 5
    //      }
    //  ]);

    const [graphTab, setGraphTab] = useState([
        {
            "title": "Next Trillion\n Dollar Opportunity",
            "cagr": props.apiresp?.ChartCAGR?.[3] ?? '',
            "date": props.apiresp?.AsOnDt?.[3] ?? '',
            "tabNo": 3
        },
        {
            "title": "Value\n Migration Strategy",
            "cagr": props.apiresp?.ChartCAGR?.[1] ?? '',
            "date": props.apiresp?.AsOnDt?.[1] ?? '',
            "tabNo": 1
        },
        // {
        //     "title": "Business Opportunity\n Portfolio",
        //     "cagr": props.apiresp?.ChartCAGR?.[2] ?? '',
        //     "date": props.apiresp?.AsOnDt?.[2] ?? '',
        //     "tabNo": 2
        // }
    ]);


    const [chartState, setChartState] = useState(props.apiresp?.ChartData);


    const [chartSeries, setChartSeries] = useState(props.apiresp?.chartSeries);

    const graphTabClickHandler = (tabNo) => {
        setActiveGraphTab(tabNo);
    }
    
    const isOnServer = typeof window === "undefined";

    return (
        <Aux>
            <div className="col-md-12 brder_top bgWhite pr-0 pl-0">
                <ul className="graphTabs">
                    {graphTab.map((curr, index) => {
                        let tabTitle = curr.title;
                        return (
                            <li
                                key={index}
                                onClick={() => graphTabClickHandler(curr.tabNo)}
                                className={`${activeGraphTab === curr.tabNo ? "activeGraphTab" : ""}`} >
                                <h4>{tabTitle.split('\n').map((item, index) => (index === 0) ? item : [<br key={index} />, item])}</h4>
                                <p className={`${activeGraphTab === curr.tabNo ? "cagrRed" : ""}`}>NAV {curr.cagr}</p>
                                <p className={`${activeGraphTab === curr.tabNo ? "cagrRed" : ""}`}>Date As on {curr.date}</p>
                            </li>
                        )
                    })}
                </ul>

                {
                    isOnServer
                        ? <SectionLoader/>
                        :
                        (chartState[activeGraphTab] !== undefined ?
                            <>
                                {
                                    chartState[activeGraphTab].length > 0 ?
                                        <Highchart
                                            chartData={chartState[activeGraphTab]}
                                            chartSeries={chartSeries[activeGraphTab]}
                                            from='landingPage'
                                            isHideBottom='yes'
                                            id='chartdivNilesh'
                                        /> :
                                        <p className='alert alert-danger mr-3'> Data Not Found </p>
                                }
                            </>
                            :
                            null
                        )
                }


            </div>
        </Aux>
    )
}
export default PerFo;