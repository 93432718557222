import React, { useState } from 'react';
import Aux from '../components/atom/children/index';
import pms1 from "../assets/images/landing/pms1.png";
import pms2 from "../assets/images/landing/pms2.png";
import pms3 from "../assets/images/landing/pms3.png";
import pms4 from "../assets/images/landing/pms4.png";
import Container from '../components/atom/container/index';
import Heading from '../components/atom/heading/h1';
import HeadingH2 from '../components/atom/heading/h2';
import HeadingH3 from '../components/atom/heading/h3';
import Image from '../components/atom/image';
import ViewAll from '../components/atom/link/viewAll';
import NextImage from '../components/atom/nextImage';
import NoFount from '../components/atom/notFound/index';
import Section from '../components/atom/section';
import Accord from '../components/molecules/accordian';
import PMSBox from '../components/molecules/fundSlider/pms';
import BannerWrapper from '../components/molecules/landing/bannerWrapper';
import Keypms from '../components/molecules/PMS/keypms';
import Performance from '../components/molecules/PMS/performance';
import Leader from '../components/molecules/PMS/pmsLeader';
import ApiCall from '../services/preLoginApi';

let api = new ApiCall();

const SECTION_1_IMAGES = [pms1, pms2, pms3, pms4]

const getPMSHomePageData = async () => {
  // return PMSHomePage.data;
  try {
    let Alldata = {};
    let payload = { "api_name": "PMSHomePage" };
    let resData = await api.PMSHomePage(payload);
    if (resData?.data?.success) {
      Alldata = resData?.data;
    }
    return Alldata;
  } catch (e) {
    throw e;
  }
}

const getPMSCategoryData = async () => {
  // return PMSFundCategory.data;
  try {
    let Alldata = {};
    let strategyPayload = { "api_name": "PMSFundCategory" };
    let resData = await api.PMSCategory(strategyPayload);
    if (resData?.data?.success) {
      Alldata = resData?.data;
    }
    return Alldata;
  } catch (e) {
    throw e;
  }
}

const getPMSGraphData = async () => {
  try {
    let Alldata = null;
    let graphPayload = { "api_name": "PMSGraphData" };
    let resData = await api.PMSGraphData(graphPayload);
    if (resData.data.success) {
      Alldata = resData.data.data;
    }

    return Alldata;
  } catch (e) {
    throw e;
  }
}

const PMShomepage = (props) => {
  let apiData = props.apiData;
  let strategyData = props.strategyApiData;

  // let imageURl = "";
  // if (Object.keys(apiData).length > 0) {
  //   if (isMobile) {
  //     imageURl = apiData.BannerMobile;
  //   } else {
  //     imageURl = apiData.Banner
  //   }
  // }

  let [graphApiData, setGraphApiData] = useState(props.graphData);

  // extra api call from client side commented
  /* useEffect(() => {
      let payload = {
          "api_name": "PMSGraphData"
      }

      api.PMSGraphData(payload).then(res => {
          if (res.data.success) {
              let getData = res.data.data;
              setGraphApiData(getData)

          }
      }).catch(error => {
          // console.log(error, 'errorCatch')
      })
  }, [])*/

  // if(graphApiData.length===0){
  //     return <Loader/>
  // }


  let data = [
    {
      "faqid": 1,
      "tags": "5,7,8",
      "question": "What is Portfolio Management Services (PMS)?",
      "answer": "<p>Portfolio Management Service is a tailor made professional service offered to cater the investments objective of different investor classes. The Investment solutions provided by PMS cater to a niche segment of clients. The clients can be Individuals or Institutions entities with high net worth. In simple words, a portfolio management service provides professional management of your investments to create wealth.</p>\n",
      "isactive": 1,
      "createdDate": "2020-08-14T08:16:25.000Z",
      "createdBy": "",
      "updatedDate": null,
      "updatedBy": null
    }, {
      "faqid": 2,
      "tags": "1,6,8",
      "question": "Will it help me on my tax status?",
      "answer": "<p>We provide each client an audited tax statement of his portfolio annually. This can be used for calculating your tax liability and hence forth filing returns. However, we advice all our clients to consult their tax consultant before filing of their tax returns.</p>\n",
      "isactive": 1,
      "createdDate": "2020-08-15T02:01:21.000Z",
      "createdBy": "",
      "updatedDate": "2020-08-15T02:18:44.000Z",
      "updatedBy": "admin@admin.com"
    }
  ];

  //assign dummyapiData if project is running in local and no banner data available in db.
  let bannerData = apiData; //; apiData

  return (
    <React.Fragment>
      {/* {Object.keys(apiData).length > 0 ? */}
      <Aux>
        {
          bannerData.NewBannerMobileSlider.length > 0
            ?
            <BannerWrapper
              data={bannerData}
            />
            : null
        }
        <div style={{ clear: "both" }}></div>
        <Container class="whympms">
          <div className="col-md-12 text-center">
            <Heading title="Why Motilal Oswal Portfolio Management Services?" class="bold section-heading-text" />
          </div>
          <div className="row">
            {apiData?.section_1?.map((item, index) =>
              <div key={index} className="col-6 col-md-6 col-lg-6">
                <div className="col-md-12 flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <div className="row">
                        <div className="col-12 col-md-4 col-lg-4 mobile-pad0">
                          <div className="img-responsive flip-icon">
                            <NextImage
                              image={SECTION_1_IMAGES[index]}
                              alt={'pms1'}
                              width={126}
                              height={98}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-8 col-lg-8">
                          <h2 className="flip-heading">{item.title}</h2>
                          <p className="flip-subHeading">{item.desc1}</p>
                        </div>
                      </div>
                    </div>
                    <div className="flip-card-back text-center">
                      <p className="back-txt">This card will flip on hover to show more details of the key statistics mentioned. We can show two- three sentences of data over here.</p>
                      <div className="col-md-12">
                        <a href="" className="information-button">Know more
                          <Image image="../assets/images/landing/rightarrowYellow.svg" class="" style={{ height: 'auto', width: 'auto' }} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>)}
          </div>
        </Container>
        <Section>
          <Container>
            <div className="col-md-12 text-center">
              <HeadingH2 title="PMS Strategies" class="bold section-heading-text" />
            </div>
            <div className="col-md-12 padd0">
              {strategyData !== null ?
                <PMSBox min={3} max={3} sliderBox={true} apiData={strategyData.data} /> : null}
            </div>
          </Container>
        </Section>
        <Container class="leaderrasec">
          <div className="col-md-12 text-center">
            <HeadingH3 title="Experienced Leadership & Management" class="bold section-heading-text" />
          </div>
          <div className="row justify-content-md-center">
            <div className="col-md-10">
              <Leader />
            </div>
          </div>
        </Container>
        <Section>
          <Container>
            <div className="col-md-12 text-center">
              <HeadingH3 title="Performance" class="bold" />
            </div>
            <div className="col-md-12">
              {graphApiData !== null ?
                <Performance apiresp={graphApiData} /> : null}
            </div>
          </Container>
        </Section>
        <Container class="keybenefitssec">
          <div className="row">
            <div className="col-md-12 text-center">
              <HeadingH3 title="Key Benefits" class="bold section-heading-text" />
            </div>
          </div>
          <div className="col-md-12">
            <Keypms />
          </div>
        </Container>
        <Section class="Avatar pmstree passiveBottm faqpmssec">
          <Container>
            <div className="row">
              <div className="col-md-12 text-center">
                <HeadingH3
                  class="text-center bold section-heading-text"
                  title="Still have some questions about PMS?"
                />
              </div>
            </div>
            <div className="row" style={{ marginTop: "20px" }}>
              <div className="col-md-6"></div>
              <div className="col-md-6">
                <Accord data={data} />
                <div className="col-md-12 padd0 text-right">
                  <ViewAll url="/faq?key=pms" />
                </div>
              </div>
            </div>
          </Container>
        </Section>
      </Aux>
      {/* : <NoFount msg="Data not found" />
      } */}
    </React.Fragment>
  );
}

export async function getServerSideProps({ req, res }) {
  // Router.reload("/");
  try {
    res.setHeader(
      'Cache-Control',
      'public, s-maxage=10, stale-while-revalidate=59'
    );
    let values = await Promise.all([getPMSHomePageData(),
    getPMSCategoryData(), getPMSGraphData()]);
    let [apiData, strategyApiData, graphData] = values;
    return {
      props: {
        apiData: apiData, strategyApiData: strategyApiData,
        graphData: graphData, statusCode: 200
      }
    }
  }
  catch (error) {
    console.log('Error', error);
    return {
      props: {
        apiData: {}, strategyApiData: {},
        graphData: null, statusCode: 503
      }
    }
  }
}

export default PMShomepage;