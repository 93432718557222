import React from 'react';
import Para from '../../atom/paragraph/aboutpara';
import Image from '../../atom/image';
import chairman from '../../../assets/images/pmsImg/RaamdeoAggarwal.png';
import NextImage from '../../atom/nextImage';


const Leader = (props) => {
    return (
        <div className="col-12 col-md-10 offset-md-2 col-lg-9 offset-lg-2 leadership-waveBG">
            <div className="sliderCus">
                {/* <Slider slidesToShow={1} id="list1" slidesToScrol={1}> */}
                <div className="row">
                    <div class="col-4 col-md-4 col-lg-4 pad0">
                        {/* <Image image="../assets/images/pmsImg/RaamdeoAggarwal.png" class="chairman-outside" /> */}
                        <div className={"chairman-outside"}>
                            <NextImage
                              image={chairman}
                              layout={'fill'}
                            />
                        </div>
                    </div>
                    <div className="col-8 col-md-8 col-lg-8 pad-leadership">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-10 col-md-10 col-lg-10">
                                    <div className="row">
                                        <h3 className="leadership-heading">Raamdeo Agrawal</h3>

                                    </div>
                                    <div className="row">
                                        <h4 className="leadership-subHeading">Chairman</h4>
                                    </div>
                                </div>
                                {/* <div className="col-2 col-md-2 col-lg-2 content-box">
                                    <div class="row">
                                        <a href="">
                                            <Image image="/assets/images/pmsImg/Linkedinlogo.png" class="right-social" />
                                        </a>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row">
                                <Para title={`Raamdeo Agrawal is the Co-Founder and Joint Managing Director of
                            Motilal Oswal Financial Services Limited (MOFSL). As Chairman of Motilal Oswal Asset Management
                            Company, he has been instrumental in evolving the investment management philosophy and framework.`} class="leadership-information" />

                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row">
                                <a href="/about-us/people" className="information-button tw-flex tw-items-center tw-justify-center">Know more <Image image="../assets/images/landing/rightarrowYellow.svg" class="" style={{verticalAlign: 'middle', height: 'auto', width: 'auto'}} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Leader